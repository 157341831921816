import { createStore } from "vuex";
import authToken from "./modules/authToken";
import customer from "./modules/customer";
import bankDetails from "./modules/bankDetails";
import deposit from "./modules/deposit";
import director from "./modules/director";
import backOffice from "./modules/backOffice";
import createPersistedState from "vuex-persistedstate";

let timer;
let noticeTimer;
export default createStore({
  state: {
    environment: "",
    addPlanModal: false,
    openSideBar: false,
    thisPlanModal: false,
    addProductModal: false,
    otpPhoneNumberModal: false,
    phoneNo: "",
    language: "english",
    signupEmail: "",
    sidebarMenu: false,
    globaAlert: { show: false, text: "", type: "" },
    notification: { show: false, text: "", type: "" },
    bankDetailsPinModal: false,
    transactionSuccessfulModal: false,
    customSuccessTransactionModal: false,
    naijaTransactionSuccessfulModal: false,
    childWindow: [],
    rerender: 0,
    confirmTransaction: false,
    rejectTransaction: false,
    transactionDetailsModal: false,
    isKycApproved: false,
    storedUploadData: {
      type: null,
      value: null,
    },
  },
  mutations: {
    SET_KYC_STATUS(state, payload) {
      state.isKycApproved = payload;
    },
    SET_CHILD_WINDOW(state, payload) {
      state.childWindow = payload;
    },
    SET_ENVIRONMENT(state, payload) {
      state.environment = payload;
    },

    setStoredUploadData(state, payload) {
      state.storedUploadData = payload;
    },
    setCustomSuccessTransactionModal(state, payload) {
      state.customSuccessTransactionModal = payload;
    },
    setNaijaTransactionSuccessfulModal(state, payload) {
      state.naijaTransactionSuccessfulModal = payload;
    },
    setOpenSideBar(state, payload) {
      state.openSideBar = payload;
    },
    setTransactionDetailsModal(state, payload) {
      state.transactionDetailsModal = payload;
    },
    setRejectTransaction(state, payload) {
      state.rejectTransaction = payload;
    },
    setConfirmTransaction(state, payload) {
      state.confirmTransaction = payload;
    },
    setAddPlanModal(state, payload) {
      state.addPlanModal = payload;
    },
    setThisPlanModal(state, payload) {
      state.thisPlanModal = payload;
    },
    setAddProductModal(state, payload) {
      state.addProductModal = payload;
    },
    setSignupEmail(state, payload) {
      state.signupEmail = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
    },
    setOtpPhoneNumberModal(state, payload) {
      state.otpPhoneNumberModal = payload;
    },
    setPhoneNo(state, payload) {
      state.phoneNo = payload;
    },
    setSidebarMenu(state, payload) {
      state.sidebarMenu = payload;
    },
    setGlobalAlert(state, payload) {
      // Clear previous timer
      clearTimeout(timer);
      // update alert state
      state.globaAlert = payload;
      // set new timer
      timer = setTimeout(() => {
        const alert = document.getElementById("globalAlert");
        state.globaAlert = { show: false, text: "", type: "" };
        if (payload.show === true) {
          alert.click();
        }
      }, 5000);
    },
    setNotification(state, payload) {
      // Clear previous timer
      clearTimeout(noticeTimer);
      // update alert state
      state.notification = payload;
      // set new noticeTimer
      noticeTimer = setTimeout(() => {
        const alert = document.getElementById("notification");
        state.notification = { show: false, text: "", type: "" };
        if (payload.show === true) {
          alert.click();
        }
      }, 5000);
    },
    setBankDetailsPinModal(state, payload) {
      state.bankDetailsPinModal = payload;
    },
    setTransactionSuccessfulModal(state, payload) {
      state.transactionSuccessfulModal = payload;
    },
    setRerender(state, payload) {
      state.rerender += payload;
    },
  },
  actions: {},
  modules: {
    authToken,
    bankDetails,
    deposit,
    backOffice,
    customer,
    director,
  },
  getters: {
    language: (state) => state.language,
    environment: (state) => state.environment,
    childWindow: (state) => state.childWindow,
    isKycApproved: (state) => state.isKycApproved,
  },
  plugins: [createPersistedState()],
});
